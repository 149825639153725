body {
  background-color: #e6ecf0;
}

/* nav */

.navbar {
  margin-bottom: 1rem;
}

.navbar a {
  font-weight: bold;
  color: white;
}

.navbar a:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.navbar-brand > img {
  display: inline-block;
  width: 20px;
  margin-bottom: 2px;
  margin-right: 5px;
  border-radius: 2px;
}

ul.nav.navbar-nav li {
  margin: 0 0.5rem;
}

.onboarding > .navbar {
  background-image: url("./images/nav-bg.png");
  background-size: 100% 100%;
}

.home-hero {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  background-image: url("./images/landing-3840.jpg");
  text-align: center;
  padding: 1rem;
  background-position: center center;
  background-size: cover;
  color: #fff;
  text-shadow: 0px 0 12px #000000;
}

@media only screen and (max-width: 1920px) {
  .home-hero {
    background-image: url("./images/landing-1920.jpg");
  }
}

@media only screen and (max-width: 1600px) {
  .home-hero {
    background-image: url("./images/landing-1600.jpg");
  }
}

@media only screen and (max-width: 1366px) {
  .home-hero {
    background-image: url("./images/landing-1366.jpg");
  }
}

@media only screen and (max-width: 1024px) {
  .home-hero {
    background-image: url("./images/landing-1024.jpg");
  }
}

@media only screen and (max-width: 768px) {
  .home-hero {
    background-image: url("./images/landing-768.jpg");
  }
}

@media only screen and (max-width: 640px) {
  .home-hero {
    background-image: url("./images/landing-640.jpg");
  }
}

.navbar-header {
  margin: 0 auto;
}

.navbar-brand {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

.github-links {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.gameinfo {
  padding-left: 5%;
  padding-right: 5%;
}

.word-end {
  color: #ff0000;
}

.word-start {
  color: #00ff00;
}

.recaptcha {
  display: inline-block;
  margin: 10px;
}
